<template>
  <BaseDialog
    :title="displayData.title"
    :btn1="displayText.btn.submit"
    :btn2="displayData.btn.cancel"
    @cancel="$emit('close')"
    @confirm="onSubmit"
    @close="$emit('close')"
  >
    <div>
      <div style="padding-bottom: 12px">
        <p v-if="showControl.hint.passwordUpdate">{{ displayText.hint.passwordUpdate }}</p>
      </div>
      <BaseElForm
        ref="formRef"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem
          v-if="showControl.formItem.name"
          :label="displayText.formItemLabel.name"
          prop="name"
        >
          <BaseElInput v-model="formData.name" :placeholder="displayText.placeholder.name" />
        </BaseElFormItem>
        <BaseElFormItem v-if="showControl.formItem.email" prop="email">
          <template #label>
            <FormItemTooltipLabel :label="displayText.formItemLabel.email">
              用於登入後台 & 忘記密碼時收取密碼重置信
            </FormItemTooltipLabel>
          </template>
          <BaseElInput v-model="formData.email" :placeholder="displayText.placeholder.email" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showControl.formItem.password"
          :label="displayText.formItemLabel.password"
          prop="password"
        >
          <BaseElInput
            v-model="formData.password"
            :type="'password'"
            show-password
            :placeholder="displayText.placeholder.password"
          />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showControl.formItem.role"
          :label="displayText.formItemLabel.role"
          prop="role"
        >
          <BaseElSelect
            v-model="formData.role"
            :disabled="disabledRoleSelect"
            :placeholder="displayText.placeholder.role"
          >
            <BaseElSelectOption
              v-for="item in availableRoleList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showControl.formItem.branch"
          :label="displayText.formItemLabel.branch"
          prop="Shops"
        >
          <ShopMultiSelect :model.sync="formData.Shops" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import {
  CreateUser,
  UpdateUser,
  UpdateUserPassword,
  UpdateUserShops,
} from '@/api/user'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import ShopMultiSelect from '@/components/Select/ShopMultiSelect.vue'
import { i18n } from '@/plugins/i18n/i18n'
import store from '@/store'
import { useBaseForm } from '@/use/useForm'
import { apiFormatAdaptor } from '@/utils/api'
import { atLeastOneLowerCaseRules, atLeastOneNumberRules, atLeastOneSpecialCharRules, atLeastOneUpperCaseRules, emailRules, minLengthRules, noEmptyRules } from '@/validation'
import { map, includes, get } from 'lodash'

import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'

export default defineComponent({
  name: 'EditAdminModal',
  components: {
    BaseDialog,
    ShopMultiSelect,
    FormItemTooltipLabel,
  },
  props: {
    action: {
      type: String,
      required: true,
    },
    selectRow: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const availableRoleList = inject('availableRoleList')
    const customRoleOptions = inject('customRoleOptions')
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()

    initFormData({
      name: null,
      email: null,
      password: null,
      role: null,
      Shops: [],
    })

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        email: [noEmptyRules(), emailRules()],
        password: [
          noEmptyRules(),
          minLengthRules(8),
          atLeastOneUpperCaseRules(),
          atLeastOneLowerCaseRules(),
          atLeastOneNumberRules(),
          atLeastOneSpecialCharRules(),
        ],
        role: [noEmptyRules()],
      }
      return rules
    })

    const curLoggingUser = computed(() => store.getters.user)

    const disabledRoleSelect = computed(() => {
      if (props.action === 'create') return false
      if (!props.selectRow) return false
      return get(props.selectRow, 'id') === get(curLoggingUser.value, 'id')
    })

    const displayText = computed(() => {
      const text = {
        title: {
          create: i18n.t('adminSetting.dialog.create.title'),
          update: i18n.t('adminSetting.dialog.update.title'),
          password: i18n.t('adminSetting.dialog.editPassword.title'),
          shop: i18n.t('adminSetting.dialog.editBranch.title'),
        },
        formItemLabel: {
          name: i18n.t('adminSetting.form.name.label'),
          email: i18n.t('adminSetting.form.email.label'),
          password: i18n.t('adminSetting.form.password.label'),
          role: i18n.t('adminSetting.form.role.label'),
          branch: i18n.t('adminSetting.form.branch.label'),
        },
        placeholder: {
          name: i18n.t('adminSetting.form.name.placeholder'),
        },
        btn: {
          cancel: i18n.t('common.button.cancel.text'),
          create: i18n.t('common.button.create.text'),
          save: i18n.t('common.button.save.text'),
        },
        hint: {
          passwordUpdate: i18n.t('adminSetting.toast.passwordUpdated'),
        },
      }
      return text
    })

    const displayData = computed(() => {
      const data = {
        title: get(displayText.value.title, props.action),
        btn: {
          submit: props.action === 'create' ? displayText.value.btn.create : displayText.value.btn.save,
        },
      }

      return data
    })

    const showManageShop = computed(() => {
      const target = customRoleOptions.value.find(item => item.role === formData.role)
      return target ? target.needSettingShops : true
    })

    const showControl = computed(() => {
      const controls = {
        formItem: {
          name: false,
          email: false,
          password: false,
          role: false,
          branch: false,
        },
        hint: {
          passwordUpdate: false,
        },
      }

      if (props.action === 'create') {
        controls.formItem.name = true
        controls.formItem.email = true
        controls.formItem.password = true
        controls.formItem.role = true
        controls.formItem.branch = true
      } else if (props.action === 'update') {
        controls.formItem.name = true
        controls.formItem.role = true
      } else if (props.action === 'password') {
        controls.formItem.password = true
      } else if (props.action === 'shop') {
        controls.formItem.branch = true
      }

      if (formData.role === 'orgAdmin' || !showManageShop.value) {
        controls.formItem.branch = false
      }

      if (props.action === 'password') {
        controls.hint.passwordUpdate = true
      }

      return controls
    })

    const syncFormData = () => {
      formData.name = props.selectRow.name
      formData.email = props.selectRow.email
      formData.role = props.selectRow.role
      formData.Shops = props.selectRow.Shops
    }

    const onSubmit = async () => {
      if (loading.value) return
      const isValid = await checkForm(formRef.value)

      if (!isValid) {
        loading.value = false
        return
      }

      const payload = {
        id: get(props.selectRow, 'id'),
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role,
        shops: map(formData.Shops, 'id'),
      }

      let apiMethod
      if (props.action === 'create') {
        apiMethod = apiFormatAdaptor(CreateUser)
      } else if (props.action === 'update') {
        apiMethod = apiFormatAdaptor(UpdateUser)
      } else if (props.action === 'password') {
        apiMethod = apiFormatAdaptor(UpdateUserPassword)
      } else if (props.action === 'shop') {
        apiMethod = apiFormatAdaptor(UpdateUserShops)
      }

      const [res, err] = await apiMethod(payload)
      loading.value = false
      if (err) {
        window.$message.error(err.message)
        return
      }

      if (props.action === 'create') {
        window.$message.success(i18n.t('common.toast.createSuccess.text'))
      } else if (props.action === 'update') {
        window.$message.success(i18n.t('common.toast.updateSuccess.text'))
      } else if (props.action === 'password') {
        window.$message.success(i18n.t('common.toast.updateSuccess.text'))
      } else if (props.action === 'shop') {
        window.$message.success(i18n.t('common.toast.updateSuccess.text'))
      }

      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      if (includes(['update', 'shop'], props.action)) {
        syncFormData()
      }
    })

    return {
      formRef,
      formData,
      formRules,
      initFormData,
      checkForm,
      loading,
      displayText,
      showControl,
      onSubmit,
      displayData,
      availableRoleList,
      disabledRoleSelect,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
